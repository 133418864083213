import request from '@/utils/request'
/* 
诊断详情从总后台获取
*/

// 单个诊断记录
// export function getDiagnostic(data) {
//   return request({
//     url: "/operator/getDiagnostic",
//     method: "get",
//     baseURL: process.env.VUE_APP_BASE_APITWO,
//     params: data
//   })
// }

// //诊断详情  数智化转型评估表
// export function selectDiagnose(data) {
//   return request({
//     url: '/operator/selectDiagnose',
//     method: 'get',
//     baseURL: process.env.VUE_APP_BASE_APITWO,
//     params: data
//   })
// }

// // 诊断详情   数智化转型政策申报分析表
// export function selectPolicyDeclaration(data) {
//   return request({
//     url: "/operator/selectPolicyDeclaration",
//     method: "post",
//     baseURL: process.env.VUE_APP_BASE_APITWO,
//     data
//   })
// }

// //下载诊断政策pdf文档
// export function downloadPdf(data) {
//   return request({
//     url: "/questionnaire/downloadPdf",
//     method: "post",
//     baseURL: process.env.VUE_APP_BASE_APITWO,
//     responseType: "blob",
//     data
//   })
// }

// //下载分析报告
// export function downloadPdfReport(data) {
//   return request({
//     url: "/operator/downloadPdfReport",
//     method: "post",
//     baseURL: process.env.VUE_APP_BASE_APITWO,
//     data
//   })
// }
//诊断记录一览
export function getDiagnosticTools(data) {
  return request({
    url: "/webQuestionnaire/getDiagnosticTools",
    method: "post",
    data
  })
}

//根据诊断id导出excel表格
export function exportExcel(data) {
  return request({
    url: "/webQuestionnaire/export",
    method: "post",
    responseType: "blob",
    data
  })
}

//诊断详情
export function selectDiagnose(query) {
  return request({
    url: '/webQuestionnaire/selectDiagnoseTwo',
    method: 'get',
    params: query
  })
}
// export function selectDiagnose(query) {
//   return request({
//     url: '/questionnaire/selectDiagnose',
//     method: 'get',
//     params: query
//   })
// }
//根据id查询信息
export function selectById(query) {
  return request({
    url: '/webQuestionnaire/getWebUserInfo',
    method: 'get',
    params: query
  })
}
//发布报告
export function updateRelease(query) {
  return request({
    url: '/questionnaire/updateRelease',
    method: 'post',
    params: query
  })
}
//获取申报政策
export function selectPolicyDeclaration(data) {
  return request({
    url: "/webQuestionnaire/selectPolicyDeclarationTwo",
    method: "post",
    data
  })
}
//获取分析报告
export function selectReport(data) {
  return request({
    url: "/questionnaire/selectReport",
    method: "post",
    data
  })
}
//历史诊断记录
export function selectDiagnosticLogout(data) {
  return request({
    url: "/questionnaire/selectDiagnosticLogout",
    method: "post",
    data
  })
}
//修改诊断书建议
export function updateDiagnose(data) {
  return request({
    url: "/webQuestionnaire/updateDiagnose",
    method: "post",
    data
  })
}
//手动修改申报政策
export function updateolicyState(data) {
  return request({
    url: "/webQuestionnaire/updateDeclarationPolicyState",
    method: "post",
    data
  })
}
//下载诊断政策doc文档
export function downloadPdf(data) {
  return request({
    url: "/webQuestionnaire/downloadPdf",
    method: "post",
    responseType: "blob",
    data
  })
}
//下载诊断政策doc文档
export function downloadPdfReport(data) {
  return request({
    url: "/webQuestionnaire/downloadPdfReport",
    method: "post",
    // responseType: "blob",
    data
  })
}
//预约记录
export function diagnosticAppointment(data) {
  return request({
    url: "/webQuestionnaire/diagnosticAppointment",
    method: "post",
    data
  })
}
//预约排期
export function updateIsSchedule(query) {
  return request({
    url: "/webQuestionnaire/updateIsSchedule",
    method: "get",
    params: query
  })
}
